.btn-main, .btn-approve, .btn-clear, .btn-wait {
  display: inline-block;
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  text-align: center;
  border: none;
  border-radius: 4px;
  color: #fff;
  padding: 5px 20px;
  text-decoration: none; }

.btn-main {
  background-color: #042968;
  font-size: 16px;
  line-height: 20px; }
  .btn-main:hover {
    background-color: #1a61ec;
    cursor: pointer; }

.btn-approve {
  background-color: #5bc55f;
  font-size: 16px;
  line-height: 20px; }
  .btn-approve:hover {
    background-color: #53de58;
    cursor: pointer; }

.btn-clear {
  background-color: #eb2626;
  font-size: 16px;
  line-height: 20px; }
  .btn-clear:hover {
    background-color: #f41111;
    cursor: pointer; }

.btn-wait {
  background-color: #ffb548;
  font-size: 16px;
  line-height: 20px; }
  .btn-wait:hover {
    background-color: #ffa011;
    cursor: pointer; }

.small {
  font-size: 12px;
  line-height: 18px; }

.upp {
  font-size: 20px;
  line-height: 30px;
  text-transform: uppercase; }

.disabled {
  opacity: 50%; }

.btn-shevron {
  text-decoration: none;
  color: #2c2c2c;
  font-family: 'Poppins', sans-serif;
  font-size: 12px;
  line-height: 18px;
  font-weight: bold;
  text-align: center;
  border: none;
  background-color: #fff;
  cursor: pointer; }

.btn-magnifier {
  border: 1px solid #dcdcdc;
  border-radius: 0 5px 5px 0;
  padding: 3px; }

h2, h3, h4, h5, h6 {
  font-weight: bold; }

h2 {
  color: #fff; }

h2 {
  font-size: 21px; }

h3, h4, h5, h6 {
  color: #2c2c2c; }

h3 {
  font-size: 20px; }

h4 {
  font-size: 18px; }

h5 {
  font-size: 16px; }

h6 {
  font-size: 12px; }

.text-upp {
  text-transform: uppercase; }

.lead {
  color: #c9c9c9; }

#finish-card {
  background-color: #fff;
  width: 1180px;
  height: 600px;
  border-radius: 30px; }
  #finish-card .card-exit {
    display: flex;
    justify-content: end;
    padding: 20px; }
  #finish-card .card-container {
    display: flex;
    text-align: center;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 70%; }
    #finish-card .card-container .card-content .card-primary-text {
      font-size: 36px;
      font-weight: 700;
      margin: 10px; }
    #finish-card .card-container .card-content .card-secondary-text {
      font-size: 16px;
      font-weight: 700;
      margin: 10px; }
    #finish-card .card-container .card-content img {
      margin: 10px;
      animation: spin 3s linear infinite; }
    #finish-card .card-container .card-content .cup {
      animation: none; }
    #finish-card .card-container .card-content a {
      margin: 10px; }

.panels .panel-available-items {
  grid-area: panel-available-items;
  margin-top: 10px; }
  .panels .panel-available-items .panel {
    width: 540px;
    height: 480px;
    margin-top: 10px; }
    .panels .panel-available-items .panel .panel-header {
      display: flex;
      justify-content: space-between;
      text-align: left;
      font-size: 13px;
      padding: 10px;
      box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.05);
      color: #2c2c2c; }
      .panels .panel-available-items .panel .panel-header input {
        width: 220px;
        border-radius: 5px 0 0 5px; }
      .panels .panel-available-items .panel .panel-header .v-line {
        height: 50px;
        width: 1px;
        background-color: #dcdcdc;
        margin-top: 10px; }
    .panels .panel-available-items .panel .empty {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      padding: 130px;
      overflow-y: scroll; }
      .panels .panel-available-items .panel .empty .btn-main {
        margin-top: 10px; }
    .panels .panel-available-items .panel .panel-items {
      height: 350px;
      overflow-y: scroll; }
    .panels .panel-available-items .panel .panel-footer {
      display: flex;
      justify-content: space-between;
      font-size: 13px;
      padding: 10px 30px;
      box-shadow: 0px -2px 5px 1px rgba(0, 0, 0, 0.05);
      margin-top: 10px; }
      .panels .panel-available-items .panel .panel-footer ul {
        display: flex;
        justify-content: center;
        text-align: center; }
        .panels .panel-available-items .panel .panel-footer ul li {
          margin-left: 5px; }
          .panels .panel-available-items .panel .panel-footer ul li:hover {
            width: 20px;
            height: 20px;
            background-color: #042968; }
          .panels .panel-available-items .panel .panel-footer ul li a {
            text-decoration: none;
            color: #2c2c2c; }
            .panels .panel-available-items .panel .panel-footer ul li a:hover {
              color: #fff; }

.panels .panel-your-offering {
  grid-area: panel-your-offering;
  margin-top: 10px; }
  .panels .panel-your-offering .panel {
    width: 360px;
    height: 610px;
    margin-top: 10px;
    overflow-y: scroll; }
    .panels .panel-your-offering .panel .empty {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      padding: 260px 20px; }

.panels .panel-their-offer {
  grid-area: panel-their-offer;
  margin-top: 10px; }
  .panels .panel-their-offer .panel {
    width: 360px;
    height: 610px;
    margin-top: 10px;
    overflow-y: scroll; }
    .panels .panel-their-offer .panel .empty {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      padding: 230px 20px; }
      .panels .panel-their-offer .panel .empty input {
        width: 300px; }
      .panels .panel-their-offer .panel .empty a {
        margin: 15px 5px 5px 5px; }

.panels .panel-addition {
  grid-area: panel-adition; }
  .panels .panel-addition .addition-box {
    width: 540px;
    height: 110px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 0px 15px 3px rgba(116, 155, 216, 0.2);
    padding: 20px 0 0 20px; }
    .panels .panel-addition .addition-box input {
      width: 300px;
      margin-right: 10px; }
    .panels .panel-addition .addition-box .btn-approve {
      margin-left: 10px; }

@media (max-width: 500px) {
  .container {
    max-width: 500px;
    padding: 10px; }
  #header-home #navbar {
    display: flex;
    justify-content: center; }
    #header-home #navbar a {
      font-size: 30px; }
    #header-home #navbar #main-menu {
      display: flex;
      text-align: center; }
      #header-home #navbar #main-menu li {
        display: none; }
        #header-home #navbar #main-menu li:last-child {
          display: inline; }
          #header-home #navbar #main-menu li:last-child .btn-main {
            font-size: 12px;
            background-color: #1a61ec;
            margin-top: 10px; }
  #header-home .header-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 60px; }
    #header-home .header-content .step-line {
      display: none; }
    #header-home .header-content h2 {
      font-size: 30px;
      margin-bottom: 100px; }
    #header-home .header-content .stepbar li {
      margin: -20px 5px 0 5px; }
      #header-home .header-content .stepbar li .step {
        width: 50px;
        height: 50px;
        line-height: 50px; }
      #header-home .header-content .stepbar li .step-title {
        display: none; }
      #header-home .header-content .stepbar li .small-img {
        width: 32px;
        height: 32px;
        line-height: 32px;
        margin-top: 10px; }
    #header-home .header-content .step-explanation {
      width: 400px;
      font-size: 12px; }
  .panels {
    display: grid;
    grid-template-areas: 'panel-available-items'
 'panel-addition'
 'panel-your-offering'
 'panel-their-offer'; }
    .panels .panel-available-items {
      margin-top: 130px; }
      .panels .panel-available-items .panel {
        width: 440px;
        height: 570px;
        margin-top: 10px; }
        .panels .panel-available-items .panel .panel-header {
          display: block; }
          .panels .panel-available-items .panel .panel-header .search {
            margin-bottom: 20px; }
          .panels .panel-available-items .panel .panel-header input {
            width: 400px; }
          .panels .panel-available-items .panel .panel-header .v-line {
            display: none; }
        .panels .panel-available-items .panel .empty {
          padding: 115px; }
    .panels .panel-addition .addition-box {
      width: 440px;
      height: 140px;
      padding: 20px 0 0 20px; }
      .panels .panel-addition .addition-box input {
        width: 400px;
        margin-bottom: 10px; }
      .panels .panel-addition .addition-box a {
        margin-left: 110px; }
    .panels .panel-your-offering {
      grid-area: panel-addition;
      margin: -20px 0 0 -520px; }
      .panels .panel-your-offering .panel {
        width: 440px;
        height: 200px; }
        .panels .panel-your-offering .panel .empty {
          padding: 80px 50px; }
    .panels .panel-their-offer {
      grid-area: panel-addition;
      margin: 240px 0 0 -520px; }
      .panels .panel-their-offer .panel {
        width: 440px;
        height: 300px; }
        .panels .panel-their-offer .panel .empty {
          padding: 100px 20px; }
  #main-footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #051429; }
    #main-footer div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center; }
      #main-footer div a {
        margin: 10px 0; }
  #finish-card {
    width: 320px; } }

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box; }

body {
  background-color: #f1f1f1;
  font-family: 'Poppins', sans-serif; }

li {
  list-style: none; }

input {
  margin-top: 10px;
  border: 1px solid #dcdcdc;
  border-radius: 5px;
  padding: 5px; }

.container {
  padding: 10px 30px;
  max-width: 1440px;
  margin: auto; }

#header-home {
  height: 300px;
  width: 100%;
  background: linear-gradient(to right, #051429, #032b6e, #0149c4);
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3); }
  #header-home #navbar {
    display: flex;
    justify-content: space-between; }
    #header-home #navbar a {
      text-decoration: none;
      color: #fff; }
    #header-home #navbar #main-menu {
      display: flex;
      text-align: center; }
      #header-home #navbar #main-menu li {
        margin: 20px 20px;
        font-size: 16px;
        font-weight: 500;
        color: #fff; }
        #header-home #navbar #main-menu li a {
          margin-top: -10px; }
          #header-home #navbar #main-menu li a img {
            margin-right: 10px; }
  #header-home .header-content {
    text-align: center; }
    #header-home .header-content .stepbar {
      display: flex;
      color: #fff;
      font-weight: 400;
      margin: 0 200px; }
      #header-home .header-content .stepbar .step {
        font-size: 18px;
        font-weight: 400;
        background-color: #fff;
        color: #2c2c2c;
        width: 32px;
        height: 32px;
        display: inline-block;
        border-radius: 50%;
        transform: translateY(-60%);
        line-height: 32px; }
      #header-home .header-content .stepbar .step-title {
        display: block;
        width: 130px;
        font-size: 11px;
        margin-top: -10px; }
      #header-home .header-content .stepbar .waiting-icon-light {
        background-color: #ffb548;
        width: 32px;
        height: 32px;
        line-height: 32px; }
        #header-home .header-content .stepbar .waiting-icon-light .small-img {
          width: 20px;
          height: 20px;
          line-height: 20px;
          margin-top: 5px;
          animation: spin 3s linear infinite; }
      #header-home .header-content .stepbar .checkmark-icon {
        background-color: #5bc55f;
        color: #fff; }
    #header-home .header-content .step-explanation {
      font-size: 18px;
      color: #2c2c2c;
      background-color: #ffb548;
      box-shadow: 0px 2px 5px 4px rgba(5, 25, 55, 0.1);
      border-radius: 10px;
      padding: 5px 0 5px 0;
      margin: 20px 150px 0 150px; }
  #header-home .step-line {
    height: 5px;
    width: 800px;
    display: block;
    margin: 40px 0 0 260px; }

.panels {
  display: grid;
  grid-template-areas: 'panel-available-items panel-your-offering panel-their-offer'
 'panel-adition panel-your-offering panel-their-offer';
  grid-gap: 20px;
  color: #2c2c2c; }
  .panels .panel {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 0px 15px 3px rgba(116, 155, 216, 0.2); }
    .panels .panel .panel-items li {
      display: flex;
      border-bottom: 1px solid #dcdcdc;
      margin: 8px 10px 0px 10px;
      padding: 2px; }
      .panels .panel .panel-items li:last-child {
        border: none; }
      .panels .panel .panel-items li .img-border {
        border: 1px solid #999;
        padding: 1px;
        margin-right: 20px; }
      .panels .panel .panel-items li .nft-items {
        width: 380px;
        margin-top: 10px; }
      .panels .panel .panel-items li .btn-main, .panels .panel .panel-items li .btn-clear, .panels .panel .panel-items li .waiting-icon-dark {
        margin-top: 16px; }
      .panels .panel .panel-items li .waiting-icon-dark img {
        animation: spin 3s linear infinite; }
      .panels .panel .panel-items li p {
        font-size: 12px;
        font-weight: 500; }

#main-footer {
  display: flex;
  justify-content: right;
  margin: 10px 0px 20px 0; }
  #main-footer .btn-clear, #main-footer .btn-main {
    margin-right: 10px; }

#showcase {
  position: relative;
  opacity: 0.5;
  z-index: -1; }
  #showcase::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(43, 43, 43, 0.5); }

#finish-card {
  position: absolute;
  top: 200px;
  left: 90px; }

.gradient-line-a {
  background: #fff; }

.gradient-line-b {
  background: linear-gradient(to right, #5bc55f 5%, #ffb548 10%, #fff 100%); }

.gradient-line-c {
  background: linear-gradient(to right, #5bc55f 20%, #ffb548 30%, #fff 100%); }

.gradient-line-d {
  background: linear-gradient(to right, #5bc55f 30%, #ffb548 50%, #fff 100%); }

.gradient-line-e {
  background: linear-gradient(to right, #5bc55f 50%, #ffb548 60%, #fff 100%); }

.gradient-line-f {
  background: linear-gradient(to right, #5bc55f 70%, #ffb548 80%, #fff 100%); }

.gradient-line-g {
  background: linear-gradient(to right, #5bc55f 80%, #ffb548 100%); }

@keyframes spin {
  from {
    transform: rotate(0 deg); }
  to {
    transform: rotate(360deg); } }
